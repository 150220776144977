import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/App/Banner"
import success from '../assets/images/form/undraw_Astronaut_re_8c33.svg'
import Seo from '../components/App/Seo'

const Success = () => {
    return (
        <Layout>
            <Seo title={"Nachricht erfolgreich verschickt"}
                 description={"Danke für Deine Anfrage. Wir melden uns schnellstmöglich bei Dir."}
            />
            <Navbar/>
            <Banner headerImage={success} mainText={"Danke für Deine Anfrage. Wir melden uns schnellstmöglich bei Dir."}
                    mainTitle={"Nachricht erfolgreich verschickt"}/>
            <Footer/>
        </Layout>
    )
};

export default Success
